import Vue from 'vue';
import Store from '../js/store.js';
import Storage from '../js/storage.js';

export default {
  start: {
    text: `Let's find something to be grateful for.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'air' },
  },

  air: {
    text: `Just take a moment to notice you have plenty of air to breathe.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'alive' },
  },

  alive: {
    text: `Take another moment to notice you are alive.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'grateful' },
  },

  grateful: {
    text: 'What else can you be grateful for?',
    control: {
      type: 'textarea',
      placeholder: `I'm grateful for...`,
      height: '7em',
    },
    buttons: { 'OK': 'howmuch', 'Skip': 'howmuch' },
    after(card, data) {
      Storage.insights.add({text:card.value, feeling:data.feeling.noun});
    }
  },

  howmuch: {
    text: 'How much {feeling.noun} do you feel now?',
    buttons: { 'Next': 'nice', },
    control: { type: 'range' },
    before: (card, data) => {
      card.value = Store.intensity[data.feeling.key] || 50;
    },
    after: (card, data) => {
      data.intensity = card.value;
      Vue.set(Store.intensity, data.feeling.key, card.value);
    }
  },

  nice: {
    text: 'Nice!',
    buttons: { 'OK': '__exit__' }
  },
}
