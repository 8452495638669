<template>
  <f7-page name="feeling">
    <wuju-navbar :menu="true" :back="true" :title="title ? title : capitalize(feeling.full, true)"></wuju-navbar>

    <f7-toolbar v-if="toolbar" position="bottom">
      <f7-link icon-ios="f7:pencil" icon-aurora="f7:pencil" icon-md="material:edit" @click="showInsightEditor()"></f7-link>
      <f7-button @click="exit()">Done</f7-button>
      <f7-link icon-ios="f7:ellipsis_vertical" icon-aurora="f7:ellipsis_vertical" icon-md="material:more_vert" popover-open=".feelings-menu"></f7-link>
    </f7-toolbar>

    <f7-popover class="feelings-menu" :backdrop="false" ref="feelingsMenu">
      <wuju-feeling-list :flavors="false" :descriptions="false" :feelings="this.feeling.stuck" :beforeLink="hideFeelingsPopup" title="Switch to">
        <f7-list-item link="#" @click="showFeelingsPopup()">More...</f7-list-item>
      </wuju-feeling-list>
    </f7-popover>

    <f7-popup class="feelings-popup popup-tablet-fullscreen" :backdrop="true" ref="feelingsPopup">
      <f7-page>
        <f7-navbar title="All Feelings">
          <f7-nav-right>
            <f7-link popup-close icon-ios="f7:xmark" icon-aurora="f7:xmark" icon-md="material:close"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <wuju-feeling-list categorize show-other :beforeLink="hideFeelingsPopup"></wuju-feeling-list>
      </f7-page>
    </f7-popup>

    <f7-sheet :opened="insightEditorOpen" position="top" @sheet:closed="insightEditorOpen = false" :backdrop="true">
      <f7-page-content>
        <f7-block>
          <textarea ref="insightTextArea" class="rows resizable" placeholder="I realize that..." :value="insightText" @change="insightText = $event.target.value"></textarea>
        </f7-block>
      </f7-page-content>
      <f7-toolbar position="bottom">
        <f7-button @click="insightEditorOpen = false" sheet-close>Cancel</f7-button>
        <f7-button @click="saveInsight()" sheet-close>Save</f7-button>
      </f7-toolbar>
    </f7-sheet>

    <f7-sheet style="height: 50%" class="faq-sheet gradient" :opened="!!faqText" position="top">
      <f7-page-content>
        <f7-block>
          <p class="italics" v-html="faqText"></p>
        </f7-block>
      </f7-page-content>
      <f7-toolbar position="bottom">
        <f7-button></f7-button>
        <f7-button @click="closeFaq()" sheet-close>Close</f7-button>
        <f7-button></f7-button>
      </f7-toolbar>
    </f7-sheet>

    <f7-card v-for="(card, index) in cards" :key="index" class="release">
      <f7-card-content>
        <div v-html="getText(card)"></div>
        <div v-if="$storage.settings.help && card.help" class="help" v-html="linebreaks(fillTemplate(card.help))"></div>
        <div v-if="card.celebrate">
          <wuju-celebrate :autostart="500" :count="card.celebrate.count || 5" :icon="card.celebrate.icon || 'heart_fill'"></wuju-celebrate>
        </div>
        <f7-list v-if="$storage.settings.help" class="faq" no-hairlines no-hairlines-between>
          <f7-list-item v-for="(item, index) in card.faq" :key="index" link="#" @click="showFaq(item)">
            <div v-html="fillTemplate(item.title)"></div>
          </f7-list-item>
        </f7-list>
        <div v-if="card.control && card.control.type == 'range'" style="position: relative">
          <f7-range class="range" :label="false" :format-label="(v) => (v/10).toFixed(1)" :min="0" :max="100" :step="1" :value="card.value" @range:changed="card.value = $event"></f7-range>
          <f7-icon v-if="! $storage.shown.get('range-hand-animation')" class="range-hand-animation" f7="hand_point_left_fill"></f7-icon>
        </div>
        <div v-if="card.control && card.control.type == 'text'">
          <f7-input class="textbox" type="text" autocapitalize="off" :placeholder="card.control.placeholder" @input="card.value = $event.target.value"></f7-input>
        </div>
        <div v-if="card.control && card.control.type == 'textarea'">
          <textarea
            ref="cardTextArea"
            class="rows resizable"
            :style="card.control.height ? `min-height: ${card.control.height}` : ''"
            :placeholder="fillTemplate(card.control.placeholder)"
            :value="card.value = (card.value || fillTemplate(card.control.default))"
            @change="card.value = $event.target.value"
            @textarea:resize="onResize()"
          ></textarea>
        </div>
        <div v-if="card.control && card.control.type == 'list'">
          <wuju-feeling-list :flavors="false" :descriptions="false" :exiturl="exiturl" :feelings="getFeelingValue(card.control.items)"></wuju-feeling-list>
        </div>
        <div v-if="card.control && card.control.type == 'breathe' && $storage.settings.breathing">
          <wuju-breathe></wuju-breathe>
        </div>
        <div class="pills" v-if="card.control && card.control.type == 'pills'">
          <f7-button :outline="! pillSelected(card, pill)" :fill="pillSelected(card, pill)" :style="card.control.block ? 'display: block; text-align: left; width: fit-content;' : ''"
            v-for="(pill, index) in getPills(card)"
            :key="index"
            :class="'pill'"
            @click="pillClick($event, card, pill)"
          >
          {{pill}}
          </f7-button>
          <f7-button v-if="card.control.other !== false" @click="pillClickOther($event, card)" :outline="true" class="pill other-button" :style="card.control.block ? 'display: block; text-align: left; width: fit-content;' : ''">
            {{card.control.other || 'other'}}
          </f7-button>
          <f7-input class="other textbox hidden" type="text" autocapitalize="off" placeholder="type here" @change="pillOther($event, card)"></f7-input>
        </div>
        <div class="checklist" v-if="card.control && card.control.type == 'checklist'">
          <wuju-checklist :items="getFeelingValue(card.control.items)" @resize="onResize()" @change="card.value = $event.values"></wuju-checklist>
        </div>
      </f7-card-content>
      <f7-card-footer v-if="! card.buttonlist">
        <f7-button v-for="(action, button) in card.buttons" :key="button" @click="doAction(action, button)" class="flex">{{fillTemplate(button)}}</f7-button>
        <f7-button class="back-one" v-if="cards.length > 1" icon-f7="arrow_up" :icon-size="12" @click="backOne()"></f7-button>
      </f7-card-footer>
      <f7-card-footer v-if="card.buttonlist">
        <f7-list style="width: 100%">
          <f7-list-item class="button-list-item" v-for="(action, button) in card.buttons" :key="button" @click="doAction(action)" link="#">{{fillTemplate(button)}}</f7-list-item>
        </f7-list>
      </f7-card-footer>
    </f7-card>
  </f7-page>
</template>

<style>
.card.release {
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
    position: relative;
}

.card.release .list {
    margin-top: 15px;
    font-size: 14px;
}

.card.release .list .item-content,
.card.release .list .item-inner {
  min-height: 0;
}

.card.release .faq.list {
    margin: 10px 0 0 0;
    /* opacity: 0.5; */
    color: var(--wuju-highlight-color);
}

.card.release .list .item-content {
    padding-left: 0;
}

.card.release .pills {
    margin-top: 10px;
}

.card.release .pill {
    display: inline-block;
    margin-right: 5px;
    margin-top: 10px;
    text-transform: unset;
    line-height: 1.5;
    height: 24px;
    border-width: 1px;
}

.card.release .celebrate {
    position: absolute;
    bottom: 0;
    right: 50px;
}

.card.release .back-one {
  display: block;
  position: absolute;
  right: -2px;
  top: 5px;
}

.card.release.disabled .back-one {
  display: none;
}

.card.release.disabled {
    opacity: 0.2 !important;
    pointer-events: none !important;
}

.card.release.disabled .breathe {
  display: none;
}

.card.release.disabled .other-button {
  display: none;
}

.card.release.disabled .other input {
  display: none;
}

.card.release:last-of-type {
    margin-bottom: 30px;
}

.card.release .help {
  font-size: 80%;
  margin-top: 10px;
  font-style: italic;
  opacity: 0.5;
}

/* gradient to show scrolling is possible */
.sheet-modal.gradient .sheet-modal-inner::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -2px;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 1.0) 110%);
    width: 100%;
    height: 50px;
}

.faq .list {
  margin-top: 0;
  color: var(--wuju-highlight-color);
  font-size: 14px;
  width: 100%;
}

.faq .item-content {
  padding-left: 0 !important;
}

i.range-hand-animation {
    color: var(--wuju-highlight-color);
    position: absolute;
    transform: rotate(45deg);
    left: calc(50% - 9px);
    top: 6px;
    z-index: 1000;
    animation: range-hand-animation 1.5s ease-in-out forwards;
  }

@keyframes range-hand-animation {
  0% { margin-left: 0px; opacity: 0}
  10% { margin-left: 0px; opacity: 1}
  35% { margin-left: +10px; opacity: 1}
  60% { margin-left: 0px; opacity: 1}
  80% { margin-left: 0px; opacity: 1}
  100% { margin-left: 0px; opacity: 0}
}

</style>


<script>
import moment from 'moment';
import Flows from '../js/flows.js';
import Utils from '../js/utils.js';
import Email from '../js/email.js';
import Feelings from '../js/feelings.js';
import Progress from '../js/progress.js';
import Narration from '../js/narration.js';
import WujuChecklist from '../components/wuju-checklist.vue';

const ADVANCE_PROGRESS_ON_CARD = 4;

export default {
  props: {
    flow: String,
    title: String,
    single: Boolean,
    exiturl: String,
    toolbar: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    'wuju-checklist': WujuChecklist,
  },
  data: function() {
    return {
      start: moment(),
      cards: [],
      initialIntensity: 0,
      intensity: 0,
      feeling: {},
      flowObject: {},
      insightEditorOpen: false,
      insightText: '',
      faqText: '',
      timeout: null,
    }
  },
  methods: {
    flowCard(card) {
      return Object.assign({id: card, value: null}, this.$data.flowObject[card]);
    },
    addCard(card) {
      card = Object.assign({}, card);
      var action = '';
      if (card.before) {
        // allow before() to skip a card
        action = card.before(card, this.$data);
      }
      // skip card if it has a `once` property
      if (! action && card.once && this.$storage.shown.check(`flow.${this.flow}.${card.id}`)) {
        action = card.once;
      }
      if (action) {
        this.doAction(action);
      } else {
        this.$$(".card:last-of-type .faq").hide();
        this.$data.cards.push(card);
        this.refocus();
        Narration.interrupt();
        if (card.narration) {
          Narration.delay(1000).play(card.narration, 0, true);
        }
        if (card.timeout) {
          this.timeout = setTimeout(() => {
            this.doAction(card.timeout.action);
          }, card.timeout.delay);
        }
      }
    },
    doAction(action, button) {
      var last = this.getLastCard();
      if (last) {
        if (button) {
          last.button = button;
        }
        if (last.after) {
          var result = last.after(last, this.$data, action, this.getAnswers());
          if (result === false) {
            return;
          } else if (result !== undefined && result !== true) {
            action = result;
          }
        }
        if (last.timeout) {
          clearTimeout(this.timeout);
        }
        if (last.control && last.control.type === 'range') {
          this.$storage.shown.set('range-hand-animation', true);
        }
      }
      if (action == '__exit__') {
        this.exit();
      } else if (action == '__feelings__') {
        this.showFeelingsPopup()
      } else if (action == '__contact__') {
        Email.openContactDialog(this.$f7);
      } else if (action == '__close__') {
        global.window.close();
      } else if (action == '__home__') {
        this.$f7router.navigate('/home', { clearPreviousHistory: true });
      } else if (action === Object(action)) {
        var url = `/feelings/${action.feeling}`;
        if (action.reason) {
          url = url + `?reason=${action.reason}`;
        }
        this.$f7router.navigate(url, { props: { exiturl: this.exiturl} });
      } else {
        if (action == '__previous__') {
          var prev = this.$data.cards[this.$data.cards.length - 2];
          action = prev.id;
        }
        this.addCard(this.flowCard(action));
        this.$$(".card.release:not(last-of-type)").addClass("disabled");
      }
      if (this.cards.length == ADVANCE_PROGRESS_ON_CARD) {
        Progress.advance();
      }
    },
    exit(url) {
      this.saveHistory();
      var defaultUrl = this.$f7.device.standalone ? '/home' : '/install';
      this.$f7router.navigate(url || this.exiturl || defaultUrl, { clearPreviousHistory: true });
    },
    gotoFeeling(key) {
      this.hideFeelingsPopup();
      this.$f7router.navigate(`/feelings/${key}`, { props: { exiturl: this.exiturl} });
    },
    getFeeling(key) {
      return Feelings[key];
    },
    getText(card) {
      var text = card.text;
      if (card.texts) {
        text = Utils.choice(card.texts);
      }
      return Utils.linebreaks(this.fillTemplate(text || ''));
    },
    getLastCard() {
      return this.cards[this.$data.cards.length - 1]
    },
    fillTemplate(text) {
      return Utils.template(text, {feeling:this.feeling, answers:this.getAnswers(), buttons:this.getButtons()});
    },
    showFeelingsPopup() {
      this.$refs.feelingsMenu.close();
      this.$refs.feelingsPopup.open();
    },
    hideFeelingsPopup() {
      this.$refs.feelingsMenu.close();
      this.$refs.feelingsPopup.close();
    },
    getPillListKey(card) {
      return `${this.flow}.${this.feeling.key}.${card.id}`;
    },
    getPills(card) {
      const key = this.getPillListKey(card);
      const defaults = this.getFeelingValue(card.control.items);
      return this.$storage.pills.get(key, defaults);
    },
    pillClick($event, card, pill) {
      var $target = this.$$($event.target);
      if (card.control.multiselect) {
        if (! card.value) {
          this.$set(card, 'value', []);
        }
        if (card.value.includes(pill)) {
          card.value = card.value.filter((v) => v !== pill);
        } else {
          card.value.push(pill);
          card.value = Utils.unique(card.value);
        }
      } else {
        card.value = pill;
      }
    },
    pillClickOther($event, card) {
      var $target = this.$$($event.target);
      var other = $target.parent().find('.other');
      $target.toggleClass('button-fill');
      if ($target.hasClass('button-fill')) {
        other.show();
        other.find('input').focus();
      } else {
        other.hide();
      }
      this.onResize();
    },
    pillOther($event, card) {
      this.$nextTick(() => {
        const pill = $event.target.value;
        const key = this.getPillListKey(card);
        const defaults = this.getFeelingValue(card.control.items);
        if (this.$storage.pills.get(key).length === 0) {
          this.$storage.pills.set(key, defaults);
        }
        if (card.control.multiselect) {
          pill.split(/[,\n]/).forEach((item) => {
            this.$storage.pills.add(key, item);
            (card.value = card.value || []).push(item);
            card.value = Utils.unique(card.value);
          });
        } else {
          card.value = pill;
          this.$storage.pills.add(key, pill);
        }
      });
    },
    pillSelected(card, pill) {
      if (Array.isArray(card.value)) {
        return card.value.includes(pill);
      } else {
        return card.value === pill;
      }
    },
    getFeelingValue(path) {
      if (Array.isArray(path)) return path;
      return Utils.getPath({feeling:this.feeling}, path);
    },
    getAnswers() {
      var answers = {};
      this.cards.forEach(card => {
        if (! (card.id in answers)) {
          answers[`${card.id}`] = card.value;
        }
      });
      return answers;
    },
    getButtons() {
      var buttons = {};
      this.cards.forEach(card => {
        if (! (card.id in buttons)) {
          buttons[`${card.id}`] = card.button;
        }
      });
      return buttons;
    },
    showInsightEditor() {
      this.insightEditorOpen = true;
      this.$nextTick(() => {
        this.$refs.insightTextArea.focus();
      });
    },
    saveInsight() {
      this.$storage.insights.add({
        text: this.insightText,
        feeling: this.$f7route.params.feeling,
      });
      this.insightText = '';
    },
    saveHistory() {
      var options = {
        feeling: this.$f7route.params.feeling,
        flow: this.flow,
        elapsed: moment() - this.start,
        // todo: store text format for history if the data structure changes and breaks
        answers: this.getAnswers(),
        intensity: {
          start: this.initialIntensity,
          end: this.intensity,
        },
      };
      this.$storage.history.add(options);
    },
    showFaq(faqItem) {
      var title = this.fillTemplate(faqItem.title);
      var text = this.fillTemplate(faqItem.text);
      this.faqText = `<p class="strong">${title}</p><p>${Utils.linebreaks(text)}</p>`;
      if (faqItem.narration) {
        Narration.interrupt().play(faqItem.narration, 0, true);
      }
    },
    closeFaq() {
      this.faqText = '';
      Narration.interrupt();
    },
    formatRangeLabel(v) {
      return ''+Math.round(1.096478*v);
    },
    backOne() {
      this.$data.cards.pop();
      this.refocus();
    },
    refocus() {
      this.$nextTick(() => {
        this.$$(".card:last-of-type").css('opacity', 1.0);
        this.$$(".card:last-of-type textarea").focus();
        this.$$(".card:last-of-type input[type='text']").focus();
        this.$$(".card:last-of-type").removeClass("disabled");
        this.$$(".page-content").scrollTop(10000, 200);
      });
    },
    onResize() {
      this.$$(".page-content").scrollTop(10000);
    },
    ...Utils,
  },
  mounted: function() {
    this.feeling = null;
    for (var feeling of Object.values(Feelings)) {
      for (var flavor of (this.$storage.flavors.get(feeling.key))) {
        if (flavor === this.$f7route.params.feeling) {
          this.feeling = Object.assign({}, feeling);
          this.feeling.noun = flavor;
          this.feeling.full = flavor;
          break;
        }
      }
      if (this.feeling) break;
      if (feeling.key === this.$f7route.params.feeling) {
        this.feeling = Object.assign({}, feeling);
      }
    }
    this.feeling = this.feeling || { name: '', description: '', noun: ''};
    if (this.$f7route.query.reason) {
      this.feeling.reason = this.$f7route.query.reason;
      this.feeling.full = this.feeling.noun + ' ' + this.$f7route.query.reason;
    }
    this.flowObject = Flows[this.feeling.flow || this.$f7route.query.flow || this.$props.flow];
    this.addCard(this.flowCard('start'));
    global.plausible('Release', {props:{feeling: this.feeling.name}});
  },
  beforeDestroy() {
    var steps = this.cards.map((c) => c.id);
    var values = {};
    this.cards.filter((c) => c.value).forEach((c) => { values[`value_${c.id}`] = c.value });
    heap.track(`Release - Path`, {
      first: steps[0],
      last: steps[steps.length - 1],
      steps: steps.join(', '),
      count: steps.length,
      elapsed: (moment() - this.start)/1000,
      feeling: this.feeling.key,
      flow: this.flow,
      ...values,
    });
  },
}
</script>