<template>
  <f7-navbar>
    <f7-nav-left v-if="back" back-link="Back" @click:back="$emit('clicked:back')"></f7-nav-left>
    <f7-nav-title :title="title" :subtitle="subtitle"></f7-nav-title>

    <f7-nav-right>
      <f7-link v-if="music" @click="toggle()">
        <f7-icon v-if="musicActive" ios="f7:speaker" aurora="f7:speaker" md="material:volume_up"></f7-icon>
        <f7-icon v-if="! musicActive" ios="f7:speaker_slash" aurora="f7:speaker_slash" md="material:volume_off"></f7-icon>
      </f7-link>
      <f7-link v-if="menu" icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
  import Music from '../js/music.js';

  export default {
    props: {
      back: {
        type: Boolean,
        default: true
      },
      menu: {
        type: Boolean,
        default: false
      },
      music: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
    },
    methods: {
      toggle() {
        Music.toggle();
      },
    },
    computed: {
      musicActive() {
        return Music.active;
      }
    }
  }
  </script>