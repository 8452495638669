import DefaultFlow from './default-flow.js';
import OtherFlow from './other-flow.js';
import PositiveFlow from './positive-flow.js';
import GratitudeFlow from './gratitude-flow.js';
import SurveyFlow from './survey-flow.js';

export default {
  'default': DefaultFlow,
  'survey': SurveyFlow,
  'positive': PositiveFlow,
  'other': OtherFlow,
  'gratitude' : GratitudeFlow,
}
