var Thoughts = {
  stress: {
    title: 'Stress thoughts',
    thoughts: [
      `I want to hide`,
      `This is too much`,
      `This is impossible`,
      `I need sugar/wine/chocolate`,
    ],
    feelings: ['tension', 'craving', 'resistance', 'anger'],
  },

  energy: {
    title: 'Low energy thoughts',
    thoughts: [
      `I can't do it`,
      `It's too hard`,
      `I'm exhausted`,
      `It'll take too long`,
    ],
    feelings: ['apathy', 'resistance', 'fear'],
  },

  anxiety: {
    title: 'Anxiety thoughts',
    thoughts: [
      `I have to stop worrying!`,
      `I hope I don't mess this up`,
      `I'm not going to make it`,
      `I'm going to fail`,
    ],
    feelings: ['tension', 'fear', 'shame', 'inadequacy']
  },

  judgement: {
    title: 'Self judgement',
    thoughts: [
      `I'm not enough`,
      `I always do this`,
      `I should do better`,
      `I am a disappointment`,
      `I should have started already`,
    ],
    feelings: ['apathy', 'shame', 'anger', 'doubt'],
  },

};

export default Thoughts;
