import { render, staticRenderFns } from "./wuju-celebrate.vue?vue&type=template&id=7f973675&"
import script from "./wuju-celebrate.vue?vue&type=script&lang=js&"
export * from "./wuju-celebrate.vue?vue&type=script&lang=js&"
import style0 from "./wuju-celebrate.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports