<template>
  <f7-page name="settings">
    <wuju-navbar :back="true" :menu="true"></wuju-navbar>
    <wuju-banner title="Settings"></wuju-banner>
    <f7-list>
      <f7-list-group>
        <f7-list-item title="Configuration" group-title></f7-list-item>
        <f7-list-item title="Narration" footer="Enable or disable narration during lessons and in the release process">
          <f7-toggle slot="after" :checked="$storage.settings.narration" @change="toggle('narration')"></f7-toggle>
        </f7-list-item>
        <f7-list-item title="Help texts & FAQs" footer="Show explanation, help texts and FAQs">
          <f7-toggle slot="after" :checked="$storage.settings.help" @change="toggle('help')"></f7-toggle>
        </f7-list-item>
        <f7-list-item title="Breathing animation" footer="Show or hide breathing animation in some questions">
          <f7-toggle slot="after" :checked="$storage.settings.breathing" @change="toggle('breathing')"></f7-toggle>
        </f7-list-item>
        <f7-list-item title="Guided sessions (BETA)" footer="Enable sharing your session or guiding a friend">
          <f7-toggle slot="after" :checked="$storage.settings.guide" @change="toggle('guide')"></f7-toggle>
        </f7-list-item>
        <f7-list-item title="Feeling flavors (BETA)" footer="Enable multiple flavors for each feeling">
          <f7-toggle slot="after" :checked="$storage.settings.flavors" @change="toggle('flavors')"></f7-toggle>
        </f7-list-item>
      </f7-list-group>
      <f7-list-group>
        <f7-list-item title="Tools" group-title></f7-list-item>
        <f7-list-item title="Reset data" link="#" @click="reset()"></f7-list-item>
        <f7-list-item title="Update version" link="#" @click="reload()"></f7-list-item>
      </f7-list-group>
    </f7-list>
  </f7-page>
</template>

<script>
import Utils from '../js/utils.js';
import Narration from '../js/narration.js';

export default {
  methods: {
    reset() {
      window.localStorage.clear();
      window.location.href = '/';
    },
    reload() {
      if (window.caches) {
        window.caches.delete('install-cache');
      }
      window.location.href = '/';
    },
    toggleNarration() {
      this.$storage.settings.narration = !this.$storage.settings.narration;
      if (! this.$storage.settings.narration) {
        Narration.interrupt();
      }
    },
    toggleHelp() {
      this.$storage.settings.help = !this.$storage.settings.help;
    },
    toggleBreathing() {
      this.$storage.settings.breathing = !this.$storage.settings.breathing;
    },
    toggleGuide() {
      this.$storage.settings.guide = !this.$storage.settings.guide;
    },
    toggle(field) {
      this.$storage.settings[field] = !this.$storage.settings[field];
    },
    ...Utils,
  },
}
</script>