import Vue from 'vue';
import Store from '../js/store.js';

export default {
  start: {
    text: 'Could you allow yourself to feel the {feeling.noun}?',
    buttons: { 'OK': 'howmuch' },
    control: { type: 'breathe' },
  },

  howmuch: {
    text: 'How much {feeling.noun} do you feel?',
    buttons: { 'Next': 'focus', },
    control: { type: 'range' },
    before: (card, data) => {
      card.value = Store.intensity[data.feeling.key] || 50;
    },
    after: (card, data) => {
      data.intensity = data.initialIntensity = card.value;
      Vue.set(Store.intensity, data.feeling.key, card.value);
    }
  },

  focus: {
    text: 'Just focus on that feeling of {feeling.noun} for a few moments. Take a breath if you need to.',
    control: { type: 'breathe' },
    buttons: { 'OK': 'allow' }
  },

  allow: {
    text: 'Can you allow the {feeling.noun} to flow freely through your body?',
    control: { type: 'breathe' },
    buttons: { 'Yes': 'enjoy', 'No': 'release' }
  },

  enjoy: {
    text: 'Just take a breath and enjoy this for a few moments.',
    control: { type: 'breathe' },
    buttons: { 'OK': 'howmuch2' }
  },

  howmuch2: {
    text: 'How much {feeling.noun} do you feel now?',
    buttons: { 'Next': 'nice', },
    control: { type: 'range' },
    before: (card, data) => {
      card.value = Store.intensity[data.feeling.key] || 50;
    },
    after: (card, data) => {
      data.intensity = data.initialIntensity = card.value;
      Vue.set(Store.intensity, data.feeling.key, card.value);
    }
  },

  nice: {
    text: 'Nice!',
    buttons: { 'OK': '__exit__' }
  },

  release: {
    text: 'It\'s OK. Sometimes it\'s hard to feel the good stuff and we need to let go of some bad stuff first.',
    control: {
      type: 'list',
      items: 'feeling.stuck',
    },
    buttons: { 'OK': '__exit__' }
  }

}
