<template>
  <f7-list>
    <f7-list-item v-if="title" :title="title" group-title></f7-list-item>
    <f7-list-group class="thought-group" v-for="(category, key) in thoughts" :key="key">
      <f7-list-item v-if="categorize" :title="category.title" group-title></f7-list-item>
      <f7-list-item
        v-for="(thought, index) in category.thoughts"
        :key="index"
        :title="thought"
        link="#"
        @click="gotoThought(key, index)"
      >
      </f7-list-item>
    </f7-list-group>
  </f7-list>
</template>

<style scoped>

</style>

<script>
import Utils from '../js/utils.js';
import Thoughts from '../js/thoughts.js';

export default {
  props: {
    exiturl: { type: String, default: null },
    play: { type: String, default: null },
    title: { type: String, default: null },
    categorize: { type: Boolean, default: true },
    categories: { type: Array, default: null },
  },
  data: function() {
    return {
    }
  },
  methods: {
    gotoThought(key, index) {
      this.$f7router.navigate(`/thoughts/${key}/${index}`, { props: { exiturl: this.exiturl, play: this.play } });
    },
    ...Utils,
  },
  computed: {
    thoughts() {
      return Utils.filterObject(Thoughts, (v, k) => (! this.categories || this.categories.includes(k)));
    }
  }
};
</script>
