var Topics = {
  work:
  {
    name: "Work",
    title: "Work",
    description: "career, business, coworkers, future",
    feelings: ['tension', 'anger', 'fear', 'shame'],
  },
  money:
  {
    name: "Money",
    title: "Money",
    description: "income, debt, the economy, the future",
    feelings: ['tension', 'craving', 'fear', 'scarcity'],
  },
  health:
  {
    name: "Health",
    title: "Health",
    description: "illness, viruses, mental health, fitness",
    feelings: ['apathy', 'craving', 'fear', 'shame'],
  },
  // relationships:
  // {
  //   title: "My relationships",
  //   name: "Relationships",
  //   description: "conflicts, things unsaid, promises broken",
  //   feelings: ['tension', 'anger', 'sadness', 'pride'],
  // },
  // family:
  // {
  //   title: "Family",
  //   name: "Family",
  //   description: "children, parents, siblings, distant family",
  //   feelings: ['tension', 'anger', 'sadness', 'pride'],
  // },
  // surroundings:
  // {
  //   title: "Surroundings",
  //   name: "Surroundings",
  //   description: "noise, discomfort, lack of space",
  //   feelings: ['tension', 'resistance', 'anger'],
  // },
  // global:
  // {
  //   title: "Global issues",
  //   name: "Global issues",
  //   description: "the environment, the economy, war, desease",
  //   feelings: ['apathy', 'anger', 'fear', 'seriousness'],

  // },
  everything:
  {
    title: "Everything",
    name: "Everything",
    description: "everything is stressful and overwhelming",
    feelings: ['apathy', 'tension', 'resistance', 'fear'],

  },
  other:
  {
    title: "Something else",
    name: "Stress",
    question: "What do you feel?",
    description: "",
    feelings: ['apathy', 'tension', 'resistance', 'fear'],

  },
};

export default Topics;
