<template>
  <f7-page name="lesson">
    <wuju-navbar title="Wuju" subtitle="be gentle to yourself" :menu="true" :back="false"></wuju-navbar>
    <wuju-banner :title="lesson.title"></wuju-banner>
    <f7-block>
      <wuju-contact></wuju-contact>
      <p class="content" v-html="linebreaks(lesson.text.main)"></p>
    </f7-block>
    <wuju-thought-list :play="lesson.narration.secondary" :categorize="true" :categories="lesson.categories"></wuju-thought-list>
    <!-- <f7-toolbar position="bottom">
      <f7-button style="width: 100%" round filled @click="next()">Next</f7-button>
    </f7-toolbar> -->
  </f7-page>
</template>

<style scoped>
  .content {
    min-height: 100px;
  }
</style>

<script>
import Lessons from '../js/lessons.js';
import Utils from '../js/utils.js';
import Narration from '../js/narration.js';

export default {
  props: {
    delay: {
      type: Number,
      default: 1000,
    }
  },
  data: function() {
    return {
        lesson: {},
        thoughts: {},
    }
  },
  methods: {
    ...Utils,
  },
  beforeMount: function() {
    this.lesson = Lessons[this.$f7route.params.lesson];
  },
  mounted() {
    Narration.interrupt()
    .delay(this.delay)
    .play(this.lesson.narration.main, 0, true);
  }
}
</script>