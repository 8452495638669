<template>
  <f7-page name="about">
    <wuju-navbar :menu="true" :back="true"></wuju-navbar>
    <wuju-banner title="About Wuju"></wuju-banner>
    <f7-block>
      <p>
        A friend dragged me to a Sedona Method introduction class one night about 10 years ago. It was a pretty powerful
        experience (and my introduction to mindfullness in general), but it didn't stick and I moved on to other ways to find happiness.
      </p>
      <p>
        A decade later, now married, with a kid, in a different country and a completely different set of emotional issues, I stumbled upon the method again, this time in the form of
        Hale Dwoskin's book, <f7-link href="https://www.amazon.com/Sedona-Method-Hale-Dwoskin-ebook/dp/B0050KEG64" target="_blank" class="external">The Sedona Method</f7-link>.
      </p>
      <p>
        Working through the book and the method this time around, I found it very helpful to do the exercises in writing. As I was writing the seminal question
        "Would you let it go?" (which this app is named after) for the umpteenth time, it dawned on me how straight forward and linear this method was and how easy it would be to make an
        app out of it.
      </p>
      <p>
        This was in 2018. Wuju has evolved quite a bit since then, but the core idea of identifying and processing our emotions as a way to greater mental health and wellbeing remains.
      </p>
      <p>
        Using this app has been revolutionary for me. I've struggled with depression in its various forms for
        most of my adult life and I tried everything from meditation to therapy to medication with limited results.
      </p>
      <p>
        This app is the best way I found to consistently get
        me out of my funk and stop the spiral of negative thoughts. My life and the lives of everyone around me is better because of it.
      </p>
      <p>
        Hope this works work you, as much as it does for me.
      </p>
      <p><f7-link target="_blank" external href="https://twitter.com/finereli">@finereli</f7-link></p>
      <img src="/static/img/eli.jpg">
    </f7-block>
    <wuju-banner title="Credits"></wuju-banner>
    <f7-block>
      <h2>Team</h2>
      <ul class="text">
        <li><f7-link target="_blank" external href="https://twitter.com/finereli">@finereli</f7-link></li>
        <li><f7-link target="_blank" external href="https://twitter.com/yaelfiner">@yaelfiner</f7-link></li>
      </ul>
      <h2>Music</h2>
      <ul class="text">
        <li><f7-link external target="_blank" href="https://soothingrelaxation.com/products/d1lh55n72u0?_pos=244&_sid=2bcba26d6&_ss=r">Sunny Mornings</f7-link> by Peder B. Helland</li>
      </ul>
      <h2>Technology</h2>
      <ul class="text">
        <li><f7-link external target="_blank" href="https://framework7.io/">Framework 7</f7-link></li>
      </ul>
      <h2>Photography</h2>
      <div>(via <f7-link external target="_blank" href="https://unsplash.com">Unsplash</f7-link>)</div>
      <ul class="text">
        <li v-for="photo in photos" :key="photo.path">
          <f7-link external target="_blank" :href="photo.link">{{photo.author}}</f7-link>
        </li>
      </ul>
    </f7-block>
  </f7-page>
</template>

<style scoped>
.thumbnail {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
}
</style>

<script>
  import moment from 'moment';
  import Utils from '../js/utils.js';

  export default {
    computed: {
      photos() {
        return process.env.UNSPLASH.map((v) => { return {
          path: v.replace(/^src/, ''),
          author: Utils.capitalize(v.replace(/^.+\//, '').replace('-unsplash.jpg', '').slice(0, -12).replace('-', ' ')),
          link: 'https://unsplash.com/photos/'+v.replace(/^.+\//, '').replace('-unsplash.jpg', '').slice(-11),
        }});
      },
    },
  }
</script>