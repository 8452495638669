<template>
  <f7-page name="home">
    <wuju-navbar :menu="true" :back="true"></wuju-navbar>
    <wuju-banner title="History"></wuju-banner>
    <div v-if="sortedHistory.length">
      <f7-card v-for="(item, index) in sortedHistory" :key="index">
        <f7-card-content>
          <!-- todo: include topic in history -->
          <!-- todo: calculate streaks from history -->
          <p>Feeling {{item.feeling}}.</p>
          <p>Went from {{intensity(item.intensity.start)}} to {{intensity(item.intensity.end)}} in {{duration(item.elapsed)}}.</p>
          <p class="rows" v-if="item.answers.learn" v-html="learn(item.answers.learn)"></p>
          <!-- <p v-for="answer, key in filterObject(item.answers, (v, k) => v)" :key="key">
            {{question(item.flow, key)}}: {{answer}}
          </p> -->
        </f7-card-content>
        <f7-card-footer>
          <p class="flex">{{moment(item.date).fromNow()}}</p>
        </f7-card-footer>
      </f7-card>
    </div>
    <f7-block v-if="! sortedHistory.length">
      <p class="center">There is no history here yet. A history of all your emotional processing will appear here.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import Utils from '../js/utils.js';
import moment from 'moment';

export default {
  methods: {
    duration(millis) {
      var secs = Math.floor(millis / 1000);
      if (secs < 60) {
        return 'less than a minute';
      } else if (secs < 60*2) {
        return 'one minute';
      } else if (secs < 60*3) {
        return 'two minutes';
      } else {
        return Math.floor(secs / 60) + ' minutes';
      }
    },
    intensity(v) {
      if (v == 0) return 0;
      if (v < 10) return 1;
      return Math.floor(v / 10);
    },
    learn(value) {
      if (Array.isArray(value)) {
        value = value.join('\n');
      }
      return Utils.linebreaks(value, "normal");
    },
    moment: moment,
    ...Utils,
  },
  computed: {
    sortedHistory() {
      return this.$storage.history.get().sort((a, b) => moment(b.date) - moment(a.date));
    },
  }
}

</script>
