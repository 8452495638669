<template>
    <f7-list>
      <f7-list-item checkbox v-for="(item, index) in items" :key="index" :title="item" :value="item" @change="changed($event)">
      </f7-list-item>
      <f7-list-item>
        <textarea class="rows resizable" placeholder="or type here" @textarea:resize="$emit('resize', $event)" @change="changedOtherValue($event)"></textarea>
      </f7-list-item>
    </f7-list>
</template>

<style scoped>
</style>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
      checked: [],
      otherValue: '',
      otherChecked: false,
    }
  },
  methods: {
    changed($event) {
      if ($event.target.checked) {
        this.checked[$event.target.value] = $event.target.checked;
      } else {
        delete this.checked[$event.target.value];
      }
      this.update();
    },
    changedOtherChecked($event) {
      this.otherChecked = $event.target.checked;
      this.update();
    },
    changedOtherValue($event) {
      this.otherValue = $event.target.value;
      this.otherChecked = !!this.otherValue;
      this.update();
    },
    update() {
      var values = Object.keys(this.checked);
      if (this.otherChecked) {
        values.push(this.otherValue);
      }
      this.$emit('change', { values } );
    }
  },
}
</script>