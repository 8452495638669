<template>
  <f7-page name="thought">
    <wuju-navbar :title="category.title" :menu="true"></wuju-navbar>
    <wuju-help-sheet page="thought"></wuju-help-sheet>
    <f7-block>
      <f7-card class="theme-background">
        <f7-card-content>
          <div class="quoted large" v-html="thought"></div>
        </f7-card-content>
      </f7-card>
    </f7-block>
    <f7-block inset>
      <p>Repetitive thoughts usually have some negative emotions attached to them.</p>
    </f7-block>
    <f7-block>
      <wuju-feeling-list :exiturl="exiturl" title="What are you feeling?" :feelings="Object.keys(feelings)"></wuju-feeling-list>
      <f7-list>
        <f7-list-item class="accordion-group-title" :accordion-item="true" title="More...">
          <f7-accordion-content>
            <wuju-feeling-list :exiturl="exiturl" :feelings="moreFeelings()" :excludeCategories="['Positive emotions', 'Mindsets']"></wuju-feeling-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </f7-block>
  </f7-page>
</template>

<style scoped>
  .tip {
    background: var(--wuju-highlight-background);
    border-radius: 5px;
    padding: 5px 10px;
  }
</style>

<script>
import Thoughts from '../js/thoughts.js';
import Feelings from '../js/feelings.js';
import Narration from '../js/narration.js';
import Utils from '../js/utils.js';

export default {
  props: {
    exiturl: String,
    play: String,
  },
  data: function() {
    return {
        category: '',
        thought: '',
        feelings: {},
        released: false,
        error: '',
    }
  },
  methods: {
    moreFeelings() {
      return Object.keys(Feelings).filter((feeling) => ! this.category.feelings.includes(feeling));
    },
    ...Utils,
  },
  beforeMount: function() {
    var params = this.$f7route.params;
    this.category = Thoughts[params.category];
    this.thought = Thoughts[params.category].thoughts[params.index];
    this.feelings = Utils.filterObject(Feelings, (v, k) => this.category.feelings.includes(k));
  },
  mounted() {
    if (this.play) {
      this.$nextTick(() => {
        Narration.play(this.play, 0, true);
      });
    }
  }

}
</script>