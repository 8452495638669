var Lessons = {
  introduction: {
    title: 'Introduction',
    text: {
      main: `
        Hey there, my name is Eli Finer.

        Welcome to Wuju.

        This app is a form of active meditation that will help you find clarity in times of stress, anxiety or emotional turmoil.

        You'll learn to identify unpleasant feelings and explore them until they fade away, which doesn't take as long as it sounds.

        So, let's start with a simple question.

        What are you stressed about?
      `,

      /* What are you stressed about? */
      secondary: `
        Stress is usually caused by a difficult ongoing situation.  But it is often made worse by our thoughts and feelings  about it.

        These feelings range from simple worry and tension to frustration and annoyance and even anger.

        Take a look at the list here and try to find something you resonate with. It doesn't have to be precise, just close enough.
      `,
    },
    narration: {
      main: 'introduction-main.mp3',
      secondary: 'introduction-secondary.mp3',
      summary: 'introduction-summary.mp3',
    },
    // categories: ['energy', 'judgement'],
    question: `What's on your mind?`,
  },
};

Object.keys(Lessons).forEach(key => {
  Lessons[key].key = key;
});

export default Lessons;
