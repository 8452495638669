<template>
  <f7-page name="topic">
    <wuju-navbar :title="topic.name" :menu="true"></wuju-navbar>
    <f7-block>
      <f7-card class="theme-background">
        <f7-card-content>
          <div>
             <div class="large center" v-html="topic.name"></div>
             <div class="center" v-html="topic.description"></div>
          </div>
        </f7-card-content>
      </f7-card>
    </f7-block>
    <wuju-feeling-list :title="topic.question ? topic.question : `What do you feel about ${topic.name.toLowerCase()}?`" :exiturl="exiturl" :feelings="Object.keys(feelings)"></wuju-feeling-list>
    <f7-list>
      <f7-list-item accordion-item class="accordion-group-title" title="More...">
        <f7-accordion-content>
          <wuju-feeling-list :exiturl="exiturl" :feelings="moreFeelings()" :excludeCategories="['Positive emotions', 'Mindsets']"></wuju-feeling-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<style scoped>
  .tip {
    background: var(--wuju-highlight-background);
    border-radius: 5px;
    padding: 5px 10px;
  }
</style>

<script>
import Topics from '../js/topics.js';
import Feelings from '../js/feelings.js';
import Utils from '../js/utils.js';
import Narration from '../js/narration.js';

export default {
  props: {
    exiturl: String,
    play: String,
  },
  data: function() {
    return {
        topic: {},
        feelings: {},
    }
  },
  methods: {
    moreFeelings() {
      return Object.keys(Feelings).filter((feeling) => ! (this.topic.feelings || []).includes(feeling));
    },
    ...Utils,
  },
  beforeMount() {
    this.topic = Topics[this.$f7route.params.topic];
    this.feelings = Utils.filterObject(Feelings, (v, k) => (this.topic.feelings || []).includes(k));
  },
  mounted() {
    if (this.play) {
      this.$nextTick(() => {
        Narration.play(this.play, 0, true);
      });
    }
  }
}
</script>