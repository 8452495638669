import DefaultFlow from './default-flow.js';

export default Object.assign({}, DefaultFlow, {
  start: {
    text: `What is your current feeling?`,
    control: {
      type: 'text',
      placeholder: 'e.g. sadness, tension, anxiety',
    },
    buttons: { 'OK': 'start2' },
    after: (card, data) => {
      if (! card.value) return false;
      data.feeling.full = data.feeling.noun = card.value.toLowerCase().trim();
    }
  },

  start2: {
    text: `Focus for a few moments on your sense of {feeling.noun}. And take a breath or two.`,
    control: { type: 'breathe' },
    buttons: { 'Next': 'howmuch', },
    narration: 'faq-release-introduction.mp3',
  },

  done: {
    text: `Nice!`,
    buttons: { 'OK': '__exit__' }
  },

  leave: {
    text: `It's OK. It can sometimes be difficult to let go of {feeling.noun}. You might feel better anyway.`,
    buttons: { 'OK': '__exit__' }
  }
});
