<template>
  <div>
    <f7-list>
      <f7-list-item v-if="title" :title="title" group-title></f7-list-item>
      <f7-list-group class="feeling-group" v-for="(feelings, category) in feelingCategories()" :key="category">
        <f7-list-item v-if="categorize" :title="category" group-title></f7-list-item>
        <f7-list-item :accordion-item="hasFlavors(feeling)"
          v-for="(feeling, key) in feelings"
          :key="key"
          :title="feeling.name"
          :footer="getDescription(feeling)"
          :class="highlight.includes(key) ? 'highlight' : ''"
          link="#"
          @click="! hasFlavors(feeling) && gotoFeeling(key)"
        >
          <f7-accordion-content v-if="hasFlavors(feeling)">
            <f7-list inset class="tight">
              <f7-list-item
                :title="feeling.noun"
                link="#"
                @click="gotoFeeling(key)"
              >
              </f7-list-item>
              <f7-list-item
                v-for="(flavor, index) in getFlavors(feeling)"
                :key="index"
                :title="flavor"
                link="#"
                @click="gotoFeeling(flavor)"
              >
              </f7-list-item>
              <f7-list-item>
                <f7-button style="display: inline-block; padding-left: 0" @click="addFlavor(feeling)">Add</f7-button>
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list-group>
      <f7-list-group v-if="showOther">
        <f7-list-item v-if="categorize" title="More" group-title></f7-list-item>
        <f7-list-item title="Something else" footer="another feeling not listed here" link="#" @click="gotoFeeling('other')"></f7-list-item>
      </f7-list-group>
      <f7-list-group>
        <slot></slot>
      </f7-list-group>
    </f7-list>
  </div>
</template>

<script>
import Utils from '../js/utils.js';
import Feelings from '../js/feelings.js';

export default {
  props: {
    // title to show (only works if categorize=false)
    title: { type: String, default: null },
    // group feelings by categories?
    categorize: { type: Boolean, default: false },
    // show descriptions?
    descriptions: { type: Boolean, default: true },
    // list of categories to exclude
    excludeCategories: { type: Array, default: () => [] },
    // list of feelings to show (key names)
    feelings: { type: Array, default: null },
    // show "Something else" typed in feeling option?
    showOther: { type: Boolean, default: false },
    // custom exit url when going into a feeling
    flavors: { type: Boolean, default: true },
    // custom exit url when going into a feeling
    exiturl: { type: String, default: null },
    // function to call before going to feeling
    beforeLink: { type: Function, default: (feeling) => {} },
    // function to call before going to feeling
    highlight: { type: Array, default: () => [] },
  },
  methods: {
    gotoFeeling(key) {
      this.beforeLink(key);
      this.$f7router.navigate(`/feelings/${key}`, { props: { exiturl: this.exiturl } });
    },
    feelingCategories() {
      var categories = Utils.groupByObjects(this.filteredFeelings(), 'category');
      for (var name of this.excludeCategories) {
        delete categories[name];
      }
      return categories;
    },
    filteredFeelings() {
      if (! this.feelings) return Feelings;
      return Utils.filterObject(Feelings, (v, k) => this.feelings.includes(k));
    },
    hasFlavors(feeling) {
      return this.$storage.settings.flavors && this.flavors && feeling.flavors && feeling.flavors.length > 0;
    },
    getFlavors(feeling, limit) {
      var flavors = this.$storage.flavors.get(feeling.key, feeling.flavors);
      return limit !== undefined ? flavors.slice(0, limit) : flavors;
    },
    addFlavor(feeling) {
      var storeFlavor = (flavor) => {
        this.$storage.flavors.add(feeling.key, flavor);
      }

      this.$f7.dialog.create({
        title: '',
        text: `What kind of ${feeling.noun} are you feeling?`,
        content: '<div class="dialog-input-field input"><input type="text" class="dialog-input" value=""></div>',
        buttons: [
          { text: 'Cancel', color: 'theme', keyCodes: [27] },
          { text: 'OK', color: 'theme', bold: true, close: false },
        ],
        onClick(dialog, index) {
          const value = dialog.$el.find('.dialog-input').val();
          if (index === 1 && value !== '') {
            storeFlavor(value);
            dialog.close();
          }
        },
        on: {
          opened: function (dialog) {
            dialog.$el.find('.dialog-input').focus();
          }
        },
        destroyOnClose: true,
      }).open();
    },
    getDescription(feeling) {
      if (this.descriptions) {
        var flavors = [feeling.noun].concat(this.getFlavors(feeling, 4)).join(', ');
        if (this.hasFlavors(feeling)) {
          return flavors;
        } else {
          return feeling.description || flavors;
        }
      }
    },
    ...Utils,
  },
}
</script>