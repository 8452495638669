<template>
  <f7-page name="test">
    <div v-for="(feeling, key) in feelings" :key="key">
      <h2>{{feeling.name}}</h2>
      <ul v-if="feeling.learn && feeling.learn.length">
        <li v-for="(tip, tipkey) in feeling.learn" :key="tipkey">{{tip}}</li>
      </ul>
    </div>
  </f7-page>
</template>

<style scoped>
  .outer {
    width: 100%;
    height: 10px;
    /* border: 1px solid white; */
  }
  .inner {
    width: 0%;
    height: 100%;
    background: orange;
    margin: auto;
    border-radius: 20px;
  }
</style>

<script>
  import Storage from '../js/storage.js';
  import Feelings from '../js/feelings.js';

  export default {
    methods: {
      add() {
        // Storage.insights.add({text: 'was angry', feeling: 'anger'});
        // Storage.history.add({reason: 'bored', location: 'chest', intensity: {start: 50, end: 25}});
        Storage.lessons.set('momo');
      }
    },
    computed: {
      storage() {
        return Storage;
      },
      feelings() {
        return Feelings;
      }
    },
  }
</script>