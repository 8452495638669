<template>
  <div class="banner theme-background">
    <p v-if="title" class="title">{{title}}</p>
    <p v-if="subtitle" class="subtitle">{{subtitle}}</p>
    <slot></slot>
  </div>
</template>

<style scoped>
.banner {
  width: 100%;
  margin: 20px 0;
  padding: 15px 0;
  position: relative;
}

.banner .title {
  margin: 0;
  text-align: center;
  font-size: 22px;
  line-height: 1;
}

.banner .subtitle {
  margin: 0;
  text-align: center;
}
</style>

<script>
import Utils from '../js/utils.js';
import Quotes from '../js/quotes.js';
export default {
  props: {
    title: String,
    subtitle: String,
  },
}
</script>