import Vue from 'vue';
import Storage from './storage.js';

var Progress = Vue.observable({
  total: 14, // total runs for 100% progress

  remaining() {
    return this.total - Storage.data.progress;
  },

  progress() {
    return Math.round(Storage.data.progress / this.total * 100);
  },

  reset() {
    Storage.data.progress = 0;
  },

  advance() {
    Storage.data.progress = Math.min(Storage.data.progress + 1, this.total + 1);
  },

  complete() {
    Storage.data.progress = this.total + 1;
  },

  message() {
    if (this.remaining() == 0) {
      return `you're a pro now!`;
    } else if (this.remaining() == 1) {
      return `only one practice run to go!`;
    } else if (this.remaining() == 2) {
      return `just two practice runs to go`;
    } else if (this.progress() == 50) {
      return `half way there, ${this.remaining()} practice runs to go`;
    } else {
      return `${this.remaining()} practice runs to go`;
    }
  }
});

export default Progress;