<template>
  <wuju-banner>
    <div class="container">
      <div class="quoted large" v-html="quote().text"></div>
      <div class="author" v-html="quote().author"></div>
    </div>
    <f7-link class="next" icon-f7="arrow_clockwise" :icon-size="12" @click="next()"></f7-link>
  </wuju-banner>
</template>

<style scoped>
.container {
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}
.quoted {
  /* margin-left: 25px;
  margin-right: 10px; */
  /* position: relative; */
  padding-top: 15px;
  padding-bottom: 20px
}

.quoted::after {
  content: '❞';
  font-size: 24px;
  position: absolute;
  top: -10px;
  left: -23px;

}

.author {
  position: absolute;
  bottom: -5px;
  right: 0px;
  font-size: 70%;
  font-style: italic;
}

.next {
  position: absolute;
  top: 9px;
  right: 7px;
  font-size: 70%;
  font-style: italic;
  color: var(--f7-block-text-color);
}
</style>

<script>
import Utils from '../js/utils.js';
import Quotes from '../js/quotes.js';
export default {
  data() {
    return {
      quotes: Utils.shuffle(Quotes),
      index: 0,
    }
  },
  methods: {
    quote() {
      return this.quotes[this.index];
    },
    next() {
      this.index = (this.index + 1) % this.quotes.length;
    },
  }
}
</script>