<template>
  <div>
    <div v-if="device === 'other' && ! sent" class="box">
      <p>Send yourself a link to Wuju so you can open and install it on your phone.</p>
      <div class="link-box"><f7-link @click="copyLink(url)" :href="url">{{url}}</f7-link></div>
      <f7-list no-hairlines no-hairlines-between style="margin-top: 10px; margin-bottom: 10px;">
        <f7-list-input placeholder="me@example.com" :floating-label="false" ref="email" type="email" @change="email = $event.target.value" :value="email"></f7-list-input>
      </f7-list>
      <f7-button round fill @click="sendLink()">Send me the link</f7-button>
    </div>
    <div v-if="sent">
      <p class="center large">
        Thank you!
      </p>
    </div>
    <div v-if="device !== 'other'">
      <f7-button :disabled="manual" round fill @click="install()">Add to Home Screen</f7-button>
      <p v-if="! manual" style="margin-bottom: 30px">Wuju is a progressive web app (PWA) that can be installed as an app on your phone.</p>
      <div v-if="manual" style="margin-top: 30px; margin-bottom: 30px;">
        <div v-if="device === 'ios'" class="box">
          <div>👉 &nbsp; Click the <f7-icon f7="square_arrow_up" size="20" style="position: relative; top: -2px;"></f7-icon> icon below</div>
          <div>👉 &nbsp; Click "Add to Home Screen"</div>
          <br>
          <div class="bullet">❓ &nbsp; If you don't see this option, copy the link and open it in Safari.</div>
          <div class="link-box"><f7-link @click="copyLink(url)" :href="url">{{url}}</f7-link></div>
        </div>
        <div v-if="device === 'android'" class="box">
          <div>👉 &nbsp; Open your browser menu &nbsp; (<f7-icon material="more_vert" size="20"></f7-icon>)</div>
          <div>👉 &nbsp; Click "Install app"</div>
          <br>
          <div class="bullet">❓ &nbsp; If you don't see this option, copy the link and open it in Chrome.</div>
          <div class="link-box"><f7-link @click="copyLink(url)" :href="url">{{url}}</f7-link></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .box {
    margin: 0 10px;
  }
  .bullet {
    padding-left: 26px ;
    text-indent: -26px ;
  }
</style>

<script>
import Utils from '../js/utils.js';
import Email from '../js/email.js';

export default {
  data: function() {
    return {
      sent: false,
      email: this.$storage.data.email,
      manual: false,
    }
  },
  methods: {
    sendLink() {
      if (this.email) {
        Email.send('wuju_template_install', { email: this.email, link: window.location.href } );
        this.sent = true;
      }
      this.$f7.toast.show({
        text: 'Email sent!',
        closeTimeout: 3000,
      });
    },
    copyLink(link) {
      this.$clipboard(link);
      this.$f7.toast.show({
        text: 'Link copied to clipboard!',
        closeTimeout: 3000,
      });
    },
    install() {
      this.manual = true;
      global.plausible('Install');
    },
    ...Utils,
  },
  computed: {
    device() {
      if (this.$f7.device.standalone) return;
      if (this.$f7.device.ios) return 'ios';
      if (this.$f7.device.androidChrome) return 'android';

      return 'other';
    },
    url() {
      return window.location.origin + '/#!/install';
    },
    domain() {
      return window.location.origin;
    },
  },
}
</script>