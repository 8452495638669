var feelings = {
  // Surface feelings
  apathy: {
    name: 'Apathy',
    // description: 'depressed, numb, bored, exhausted, foggy',
    flavors: ['numbness', 'fogginess', 'exhaustion', 'burnout'],
    locations: ['mind', 'body', 'face', 'jaw', 'eyes', 'heart'],
    noun: /* Could you feel the */ 'apathy',
    category: 'Surface feelings',
    next: ['resistance', 'anger', 'fear', 'shame'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['life', 'money', 'family', 'work', 'health'],
    },
    learn: [
      `I'm carrying a lot of repressed pain`,
      `I'm so overwhelmed that I can't feel anything`,
      `I don't have enough space to feel what I need to feel`,
    ],
  },
  tension: {
    name: 'Tension',
    // description: 'stress, overwhelm, rush, tightness',
    locations: ['mind', 'head', 'face', 'jaw', 'eyes', 'neck', 'shoulders', 'chest', 'upper belly', 'lower belly'],
    flavors: ['stress', 'overwhelm', 'rush', 'tightness'],
    noun: /* Could you feel the */ 'tension',
    adjective: 'tense',
    category: 'Surface feelings',
    next: ['fear', 'doubt', 'peace', 'gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['time', 'money', 'family', 'work', 'health'],
    },
    learn: [
      `I'm tired and I need a break`,
      `I'm pushing myself too hard`,
      `It's OK to relax a little`,
    ]
  },
  craving: {
    name: 'Craving',
    // description: 'can\'t stop, can\'t let go, addicted, must have',
    flavors: ['need', 'lack', 'addiction'],
    locations: ['mouth', 'hands', 'chest', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'craving',
    category: 'Surface feelings',
    next: ['anger', 'shame', 'peace', 'gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What are you craving for?`,
      options: ['love', 'safety', 'intimacy', 'touch', 'distraction', 'food', 'a smoke', 'a drink', 'quiet', 'space'],
    },
    learn: [
      `I might be addicted`,
      `I need love, connection or appreciation`,
      `What I'm craving won't solve the real problem`,
    ]
  },
  resistance: {
    name: 'Resistance',
    // description: 'stuck, confused, unmoving, can\'t decide',
    flavors: ['confusion', 'creative block', '"don\'t wanna"'],
    locations: ['mind', 'face', 'eyes', 'jaw', 'hands', 'chest', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'resistance',
    category: 'Surface feelings',
    next: ['anger', 'fear', 'sadness'],
    stuck: ['doubt', 'fear'],
    extra: {
      question: `What are you resisting?`,
      options: ['my thoughts', 'my feelings', 'my body', 'my life', 'my past', 'my future', 'everything'],
    },
    learn: [
      `I need to pause and reflect`,
      `I need to set clear boundaries`,
      `I need to find a way to accept this`,
    ]
  },

  // Core emotions
  fear: {
    name: 'Fear',
    // description: 'worry, restlessness, anxiety, panic',
    flavors: ['worry', 'restlessness', 'anxiety', 'dread'],
    locations: ['face', 'mouth', 'neck', 'arms', 'chest', 'upper belly', 'lower belly', 'upper back', 'lower back', 'knees'],
    noun: /* Could you feel the */ 'fear',
    category: 'Core emotions',
    next: ['anger', 'sadness', 'courage'],
    stuck: ['resistance', 'doubt'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['letting go', 'the future', 'loneliness', 'embarrassment', 'failure', 'success', 'regret', 'pain', 'death'],
    },
    learn: [
      `I'm afraid of my fear`,
      `The only way out is through`,
      `I'm reacting to the past, not the present`,
    ]
  },
  anger: {
    name: 'Anger',
    // description: 'annoyance, frustration, bitterness, disappointment',
    flavors: ['annoyance', 'frustration', 'bitterness', 'disappointment', 'jealousy'],
    locations: ['face', 'jaw', 'shoulders', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'anger',
    category: 'Core emotions',
    next: ['sadness', 'shame', 'gratitude'],
    stuck: ['resistance', 'fear', 'pride'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['myself', 'other people', 'my life', 'my body', 'my thoughts', 'my emotions', 'everything'],
    },
    learn: [
      // `I'm covering fear with anger`,
      `There's something I need to say`,
      // `Someone has crossed my boundaries`,
      `I've compromised or given too much`,
      `I'm stuck in my ways, opinions or beliefs`,
    ]
  },
  sadness: {
    name: 'Sadness',
    // description: 'loneliness, self pity, heartbreak, pain, grief',
    flavors: ['loneliness', 'pain', 'loss', 'grief', 'self pity'],
    locations: ['face', 'eyes', 'mouth', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'sadness',
    category: 'Core emotions',
    next: ['gratitude', 'peace'],
    stuck: ['resistance', 'doubt', 'seriousness'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['myself', 'my past', 'my future', 'my home', 'my life', 'love', 'death', 'everything'],
    },
    learn: [
      `My heart is full of love`,
      // `I need to reach out instead of waiting`,
      `I'm focused on the past, instead of the present and future`,
      // `There's something I need to let go of`,
      `I need more space to process my feelings`,
    ]
  },
  shame: {
    name: 'Shame',
    // description: 'embarrassment, humiliation, failure',
    flavors: ['embarrassment', 'guilt', 'inadequacy', 'failure', 'self-judgement', 'worthlessness'],
    locations: ['face', 'ears', 'mouth', 'throat', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'shame',
    category: 'Core emotions',
    next: ['fear', 'anger', 'sadness'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['my thoughts', 'my feelings', 'my body', 'my mind', 'my life', 'my past', 'my future', 'everything'],
    },
    learn: [
      `I need to make amends`,
      `My parents weren't perfect`,
      `I am unique and very special`,
      `Failure is success in progress`,
      `I need to surround myself with better people`,
    ]
  },

  // Mindsets
  doubt: {
    name: 'Doubt',
    // description: 'skeptical, suspicious, self-doubt',
    flavors: ['self-doubt', 'distrust', 'skepticism', 'cynicism'],
    noun: /* Could you feel the */ 'doubt',
    category: 'Mindsets',
    stuck: ['resistance', 'fear', 'seriousness'],
    next: ['apathy', 'resistance', 'tension'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['myself', 'my feelings', 'my thoughts', 'the future', 'this process', 'other people', 'everything'],
    },
    learn: [
    ]
  },
  pride: {
    name: 'Pride',
    // description: 'arrogant, hurt ego, you\'re wrong, I\'m right',
    flavors: ['arrogance', 'ego', 'righteousness'],
    noun: /* Could you feel the */ 'pride',
    category: 'Mindsets',
    next: ['sadness', 'gratitude', 'fear', 'anger'],
    stuck: ['resistance', 'doubt', 'fear'],
    learn: [
    ]
  },
  scarcity: {
    name: 'Scarcity',
    // description: 'sense of lack, not enough, too late, need more',
    flavors: ['lack', '"not enough"', '"too late"'],
    noun: /* Could you feel the */ 'scarcity',
    category: 'Mindsets',
    next: ['gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
  },
  seriousness: {
    name: 'Seriousness',
    // description: 'when you let go of gravity, everything seems lighter',
    flavors: ['gravity', 'inevitability', 'hopelessness'],
    noun: /* Could you feel the */ 'seriousness',
    category: 'Mindsets',
    next: ['joy', 'gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
  },

  // Positive emotions
  gratitude: {
    name: 'Gratitude',
    description: 'abundance, trust, acceptance',
    noun: /* Could you feel the */ 'gratitude',
    category: 'Positive emotions',
    flow: 'gratitude',
    stuck: ['resistance', 'doubt', 'fear'],
  },
  courage: {
    name: 'Courage',
    description: 'passionate, excited, determined',
    noun: /* Could you feel the */ 'courage',
    category: 'Positive emotions',
    flow: 'positive',
    stuck: ['resistance', 'doubt', 'fear'],
  },
  joy: {
    name: 'Joy',
    description: 'joyful, happy, cheerful',
    noun: /* Could you feel the */ 'joy',
    category: 'Positive emotions',
    flow: 'positive',
    stuck: ['resistance', 'doubt', 'fear'],
  },
  peace: {
    name: 'Peacefulness',
    description: 'soft, loving, warm, pleasant',
    noun: /* Could you feel the */ 'peacefulness',
    category: 'Positive emotions',
    flow: 'positive',
    stuck: ['resistance', 'doubt', 'fear'],
  },
};

Object.keys(feelings).forEach(key => {
  feelings[key].help = feelings[key].help || {};
  feelings[key].stuck = feelings[key].stuck || ['resistance', 'doubt', 'fear'];
  feelings[key].extra = feelings[key].extra || {};
  feelings[key].full = feelings[key].noun;
  feelings[key].key = key;
});

export default feelings;
