<template>
  <f7-page name="graph">
    <div id="graph"></div>
  </f7-page>
</template>

<style>
  #graph svg {
    width: 100%;
  }
</style>

<script>
  import {graphviz} from "d3-graphviz";
  import Flows from '../js/flows.js';
  import Feelings from '../js/feelings.js';
  import Utils from '../js/utils.js';

  export default {
    mounted() {
      this.$$('body').removeClass('app');

      const wrap = (s) => s.replace(
          /(?![^\n]{1,16}$)([^\n]{1,16})\s/g, '$1\n'
      );
      var feeling = Object.assign({}, Feelings[this.$f7route.query.feeling || 'tension']);
      var flow = Flows[this.$f7route.params.flow];
      var edges = [];
      var nodes = [];

      var deadend = 1;
      var exit = 1;
      Object.keys(flow).forEach((key) => {
        var state = flow[key];
        var text = Utils.template(state.text, {feeling: feeling, answers: {}}) || key;
        text = Utils.shorten(text, 15);
        var links = state.graph_links || state.buttons;
        Object.keys(links).forEach((button) => {
          var weight = 1;
          var action = links[button];
          if (action === '') {
            action = `deadend_${deadend}`;
            nodes.push(`${action} [color=red] [style=filled] [fontcolor=white];`)
            deadend++;
          } else if (action === '__exit__') {
            action = `exit_${exit}`;
            nodes.push(`${action} [color=yellow] [style=filled];`)
            exit++;
          } else if (action.feeling) {
            action = `goto_${action.feeling}`;
            nodes.push(`${action} [color=green] [style=filled];`)
          }
          button = Utils.template(button, {feeling: feeling, answers: {}});
          edges.push(`${key} -> ${action} [label="${button}"] [weight=${weight}];`);
        });
        nodes.push(`${key} [label="${wrap(text)}"] [shape=box];`)
      });

      graphviz('#graph', { scale: 0.8 } ).renderDot(`
        digraph {
          ${nodes.join('\n')}
          ${edges.join('\n')}
        }
        `).onerror((e) => {
          console.error(e)
      });
    },
    beforeDestroy() {
      this.$$('body').addClass('app');
    }
}
</script>