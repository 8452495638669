<template>
  <f7-page name="home">
    <wuju-navbar :menu="true" :back="false" title="Wuju" subtitle="be gentle with yourself"></wuju-navbar>
    <wuju-help-sheet page="home"></wuju-help-sheet>
    <f7-toolbar tabbar position="bottom">
      <f7-link tab-link="#tab-thoughts" :tab-link-active="$storage.settings.tab === 'thoughts'">Thoughts</f7-link>
      <f7-link tab-link="#tab-feelings" :tab-link-active="$storage.settings.tab === 'feelings'">Feelings</f7-link>
      <f7-link tab-link="#tab-guide" v-if="$storage.settings.guide">Guide</f7-link>
    </f7-toolbar>
    <wuju-quote ref="quoteBlock" :quotes="quotes"></wuju-quote>
    <f7-block >
      <!-- <f7-block v-if="progress.progress() <= 100">
          <wuju-progress :progress="progress.progress()" :label="`${progress.message()}`"></wuju-progress>
          <f7-button v-if="nextLesson" href="/lessons" class="small no-margin">Up Next: {{nextLesson.title}}</f7-button>
      </f7-block>
      <wuju-celebrate v-if="progress.progress() == 100" :autostart="500"></wuju-celebrate> -->
    </f7-block>
    <f7-tabs>
      <f7-tab id="tab-thoughts" :tab-active="$storage.settings.tab === 'thoughts'"  v-on:tab:show="$storage.settings.tab = 'thoughts'">
        <wuju-thought-list :exiturl="exiturl"></wuju-thought-list>
      </f7-tab>
      <f7-tab id="tab-feelings" :tab-active="$storage.settings.tab === 'feelings'" v-on:tab:show="$storage.settings.tab = 'feelings'">
        <wuju-feeling-list categorize show-other :highlight="highlight" :exiturl="exiturl"></wuju-feeling-list>
      </f7-tab>
      <f7-tab id="tab-guide" v-if="$storage.settings.guide" v-on:tab:show="hideQuote()" v-on:tab:hide="showQuote()">
        <wuju-banner title="Guide a friend"></wuju-banner>
        <f7-block>
          <f7-block>
            <p>Wuju can be a little intimidating the first time around.<br>Helping a friend through their first session could make a huge difference.</p>
            <p>Share the link below with them and you'll be able to see their process in real time.</p>

            <div class="link-box"><a v-on:click="copyLink(shareLink(true))">{{ shareLink(true) }}</a></div>
            <wuju-share :url="shareLink(true)" :include="['messenger', 'whatsapp', 'sms']"></wuju-share>
            <br>
            <f7-button round fill :href="viewLink(false)">View their session</f7-button>
            <f7-button class="small" @click="shareSession()">or share yours</f7-button>
          </f7-block>
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<style scoped>
  .video-thumb {
    width: 50px;
    float: right;
    margin: 0 0 10px 10px;
  }
</style>

<script>
import Utils from '../js/utils.js';
import Thoughts from '../js/thoughts.js';
import Sensations from '../js/sensations.js';
import Progress from '../js/progress.js';
import Quotes from '../js/quotes.js';
import Lessons from '../js/lessons.js';
import Narration from '../js/narration.js';
import Record from '../js/record.js';

export default {
  props: {
    install: {
      type: Boolean,
      default: true,
    },
    shared: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Array,
      default: () => [],
    },
    play: {
      type: String,
      default: null
    },
    exiturl: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      thoughts: Thoughts,
      sensations: Sensations,
      quotes: Quotes,
    }
  },
  methods: {
    showQuote() {
      this.$$(this.$refs.quoteBlock.$el).show();
    },
    hideQuote() {
      this.$$(this.$refs.quoteBlock.$el).hide();
    },
    shareLink(external) {
      return (external ? window.location.origin + '/#!' : '') + '/share/' + Record.groupId();
    },
    viewLink(external) {
      return (external ? window.location.origin + '/#!' : '') + '/view/' + Record.groupId();
    },
    copyLink(link) {
      this.$clipboard(link);
      this.$f7.toast.show({
        text: 'Share link copied to clipboard!',
        closeTimeout: 4000,
      });
    },
    shareSession() {
      this.copyLink(this.viewLink(true));
      this.$f7router.navigate(this.shareLink(), { clearPreviousHistory: true });
    },
    ...Utils,
  },
  computed: {
    progress() {
      return Progress;
    },
    lessons() {
      return Lessons;
    },
    nextLesson() {
      for (var lesson of Object.values(Lessons)) {
        if (! this.$storage.lessons.get(lesson.key)) return lesson;
      }
    },
  },
  mounted() {
    Utils.clearObject(this.$store.intensity);
    if (this.play) {
      this.$nextTick(() => {
        Narration.play(this.play, 0, true);
      });
    }
    if (this.shared) {
      this.$store.shared = true;
      Record.record(this.$f7route.params.group);
    }
    if (Progress.progress() > 0) {
      global.plausible('Return', {props:{progress: Progress.progress()}});
    }
  },
}
</script>